import {
    createSlice,
    createAsyncThunk,
    PayloadAction,
    createSelector,
} from '@reduxjs/toolkit'
import _ from 'lodash'
import { RootState } from 'store'
import { typedRequest } from 'store/_utils/api-fetch'
import getHeaders from 'store/_utils/get-headers'
import { LayoutStatus, LayoutType, PhoenixLayout } from './types/layouts'
import {
    FetchLayoutResponse,
    FetchLayoutsResponse,
    LayoutPublishPayload,
} from './api-types'
import {
    BlockUpdatePayload,
    PhoenixBlock,
    PhoenixBlockTemplate,
} from './types/blocks'
import { selectCBTEntities } from './block-templates-selectors'

const baseAPIUrl = import.meta.env.VITE_TC_BACKEND_API as string
const phoenixAPI = `${baseAPIUrl}/phoenix`
const appStudioAPI = `${baseAPIUrl}/v1/app-studio` // phoenix naming will be deprecated

type PhoenixLayoutState = {
    fetchedLayouts: PhoenixLayout[]
    currentSelectedLayout:
        | (PhoenixLayout & {
              skipStateUpdateWithAPIResponse?: boolean
          })
        | null
    loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
    error?: string
    initialized: boolean
}

const initialState: PhoenixLayoutState = {
    fetchedLayouts: [],
    currentSelectedLayout: null,
    loading: 'idle',
    error: undefined,
    initialized: false,
}

type CloneLayoutToDraftResponse = {
    newDraftLayout: PhoenixLayout
}
export const cloneLayoutToDraft = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    { layoutId: string },
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/cloneLayoutToDraft', async ({ layoutId }, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<CloneLayoutToDraftResponse>(
        `${appStudioAPI}/layouts/${appId}/${layoutId}/clone`,
        {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    const draftLayout = response.newDraftLayout

    return decodeLayout(draftLayout)
})

export const archiveLayoutById = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    { layoutId: string },
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/archiveLayout', async ({ layoutId }, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<{ archivedLayout: PhoenixLayout }>(
        `${appStudioAPI}/layouts/${appId}/${layoutId}/archive`,
        {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    return decodeLayout(response.archivedLayout)
})

export const fetchDraftLayoutByType = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout | undefined,
    // First argument to the payload creator
    LayoutType,
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/fetchDraftLayoutByType', async (layoutType, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<FetchLayoutsResponse>(
        `${phoenixAPI}/layouts/${appId}?type=${layoutType}&limit=1&sort=desc&readonly=false`,
        {
            method: 'GET',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    if (!response?.length) throw new Error('No PLP layout found')

    const layout = response[0]

    return decodeLayout(layout)
})

export const fetchLayoutById = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout | undefined,
    // First argument to the payload creator
    string,
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/fetchLayoutById', async (layoutId, { getState }) => {
    // Check if the layout is already in the fetched layouts
    const { phoenixLayouts } = getState()

    // If the current selected layout is the same as the one we are fetching, return it
    if (phoenixLayouts.currentSelectedLayout?._id === layoutId) {
        return phoenixLayouts.currentSelectedLayout
    }

    // If the layout is in the fetched layouts, return it
    const cachedLayout = phoenixLayouts.fetchedLayouts.find(
        (layout) => layout._id === layoutId
    )

    if (cachedLayout) {
        // No need to decode blocks, cached layout should already have decoded blocks
        return cachedLayout
    }

    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<FetchLayoutResponse>(
        `${phoenixAPI}/layouts/${appId}/${layoutId}`,
        {
            method: 'GET',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    const layout = response

    return decodeLayout(layout)
})

export const fetchLayoutsByType = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout[],
    // First argument to the payload creator
    {
        type: LayoutType
        limit?: number
        sort?: 'asc' | 'desc'
        readOnly?: boolean
        status?: LayoutStatus[]
        hydrate?: boolean
        published?: boolean
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'phoenix/fetchLayoutsByType',
    async (
        {
            type,
            limit = '10',
            sort = 'desc',
            hydrate = true,
            readOnly,
            status,
            published,
        },
        { getState }
    ) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const queryParams = new URLSearchParams({
            type,
            limit: limit.toString(),
            sort,
            hydrate: hydrate.toString(),
        })
        if (status) {
            queryParams.append('status', status.join(','))
        }
        if (readOnly) {
            queryParams.append('readonly', readOnly.toString())
        }

        if (published) {
            queryParams.append('published', published.toString())
        }

        const response = await typedRequest<FetchLayoutsResponse>(
            `${phoenixAPI}/layouts/${appId}?${queryParams.toString()}`,
            {
                method: 'GET',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
            }
        )

        const layouts = response.map((layout) => decodeLayout(layout))

        return layouts
    }
)

export const savePhoenixBlock = createAsyncThunk<
    // Return type of the payload creator
    PhoenixBlock,
    // First argument to the payload creator
    { layoutId: string; block: BlockUpdatePayload; tab?: string },
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/saveBlock', async ({ layoutId, block }, { getState }) => {
    const blockId = block._id

    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<PhoenixBlock>(
        `${phoenixAPI}/layouts/${appId}/${layoutId}/blocks/${blockId}`,
        {
            method: 'PUT',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...block,
                _id: undefined,
                layoutId: undefined,
            }),
        }
    )

    const decodedBlock = decodeBlock(response)

    return decodedBlock
})

export const revertPhoenixBlock = createAsyncThunk<
    // Return type of the payload creator
    PhoenixBlock,
    // First argument to the payload creator
    { layoutId: string; blockId: string; tab?: string },
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/revertBlock', async ({ layoutId, blockId, tab }, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<PhoenixBlock>(
        `${phoenixAPI}/layouts/${appId}/${layoutId}/blocks/${blockId}/revert`,
        {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                tab,
            }),
        }
    )

    const decodedBlock = decodeBlock(response)

    return decodedBlock
})

export const replaceBlockInLayout = createAsyncThunk<
    // Return type of the payload creator
    { layout: PhoenixLayout; newBlockId: string },
    // First argument to the payload creator
    {
        layoutId: string
        block: BlockUpdatePayload
        tabTitle?: string
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'phoenix/replaceBlockInLayout',
    async ({ layoutId, block, tabTitle }, { getState }) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const response = await typedRequest<{
            layout: PhoenixLayout
            newBlockId: string
        }>(
            `${phoenixAPI}/layouts/${appId}/${layoutId}/blocks/${block._id}/replace`,
            {
                method: 'POST',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...block,
                    tab: tabTitle,
                    _id: undefined,
                    layoutId: undefined,
                }),
            }
        )

        return {
            layout: decodeLayout(response.layout),
            newBlockId: response.newBlockId,
        }
    }
)

export const cloneBlockTemplateToLayout = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    {
        layoutId: string
        templateId: string
        position: number
        manifestConfig: any
        tab?: string
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'phoenix/cloneBlockTemplateToLayout',
    async (
        { layoutId, templateId, position, manifestConfig, tab },
        { getState, rejectWithValue }
    ) => {
        try {
            const { app } = getState()

            const appData = app.data as { id: string }
            const appId = appData.id

            const headers = await getHeaders()

            const response = await typedRequest<PhoenixLayout>(
                `${appStudioAPI}/layouts/${appId}/${layoutId}/blocks`,
                {
                    method: 'POST',
                    headers: {
                        ...headers,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        templateId,
                        position,
                        manifestConfig,
                        tab,
                    }),
                }
            )

            return decodeLayout(response)
        } catch (error) {
            return rejectWithValue(error || 'An error occurred')
        }
    }
)

export const updateLayoutBlockList = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    {
        layoutId: string
        blockIds: string[]
        tab?: string
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'phoenix/updateLayoutBlockList',
    async ({ layoutId, blockIds, tab }, { getState }) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const response = await typedRequest<PhoenixLayout>(
            `${appStudioAPI}/layouts/${appId}/${layoutId}/blocks`,
            {
                method: 'PUT',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    blockIds,
                    tab,
                }),
            }
        )

        return decodeLayout(response)
    }
)

export const updateLayoutSettings = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    {
        layoutId: string
        contentLayout?: PhoenixLayout['contentLayout']
        content?: PhoenixLayout['content']
        settingsConfig?: Partial<PhoenixLayout['settingsConfig']>
        alignBlocksAndFirstTab?: boolean
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'phoenix/updateDraftLayout',
    async (
        {
            layoutId,
            settingsConfig,
            contentLayout,
            content,
            alignBlocksAndFirstTab,
        },
        { getState }
    ) => {
        const {
            app,
            phoenixLayouts: {
                currentSelectedLayout: {
                    // @ts-expect-error - We know this is not null at this point
                    settingsConfig: currentSettingsConfig,
                },
            },
        } = getState()
        const appData = app.data as { id: string }
        const appId = appData.id
        const headers = await getHeaders()

        const body: Record<string, any> = {}

        if (settingsConfig) {
            body['settingsConfig'] = {
                ...currentSettingsConfig,
                ...settingsConfig,
            }
        }

        if (contentLayout) {
            body['contentLayout'] = contentLayout
        }

        if (content) {
            body['content'] = content
        }

        if (alignBlocksAndFirstTab) {
            body['alignBlocksAndFirstTab'] = alignBlocksAndFirstTab
        }

        const response = await typedRequest<{
            status: number
            updatedLayout: PhoenixLayout
        }>(`${phoenixAPI}/layouts/${appId}/${layoutId}`, {
            method: 'PUT',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })

        const decodedLayout = decodeLayout(response.updatedLayout)

        return {
            ...decodedLayout,
            settingsConfig: {
                ...decodedLayout.settingsConfig,
                ...settingsConfig,
            },
        }
    }
)

export const updateDraftVersion = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    {
        layoutId: string
        label: string
    },
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/updateDraftVersion', async ({ layoutId, label }, { getState }) => {
    const { app } = getState()
    const appData = app.data as { id: string }
    const appId = appData.id
    const headers = await getHeaders()

    const response = await typedRequest<{
        status: number
        updatedLayout: PhoenixLayout
    }>(`${phoenixAPI}/layouts/${appId}/${layoutId}`, {
        method: 'PUT',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            label,
        }),
    })

    return {
        ...response.updatedLayout,
    }
})

export const unscheduleLayoutById = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    { layoutId: string },
    // Types for ThunkAPI
    { state: RootState }
>('phoenix/unscheduleLayout', async ({ layoutId }, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const { layout } = await typedRequest<{ layout: PhoenixLayout }>(
        `${appStudioAPI}/layouts/${appId}/${layoutId}/remove-scheduled-publish`,
        {
            method: 'DELETE',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    return layout
})

export const publishLayout = createAsyncThunk<
    // Return type of the payload creator
    PhoenixLayout,
    // First argument to the payload creator
    LayoutPublishPayload & {
        layoutId: string
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'phoenix/publishLayout',
    async (
        publishPayload: LayoutPublishPayload & { layoutId: string },
        { getState }
    ): Promise<PhoenixLayout> => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const { layoutId, publishType } = publishPayload

        try {
            // Response payload depends on the action. If we are publishing instantly, we get the published layout back and a new draft layout.
            // If we are scheduling, we get the scheduled layout back. Other layouts will be undefined.
            const response = await typedRequest<{
                newDraftLayout?: PhoenixLayout
                publishedLayout?: PhoenixLayout
                scheduledLayout?: PhoenixLayout
            }>(`${phoenixAPI}/layouts/${appId}/${layoutId}/publish`, {
                method: 'POST',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(publishPayload),
            })

            const { scheduledLayout, publishedLayout } = response

            if (publishedLayout) {
                return decodeLayout(publishedLayout)
            }

            if (scheduledLayout) {
                return decodeLayout(scheduledLayout)
            }

            throw new Error('No layout returned from API')
        } catch (error) {
            console.error('Error during API request:', error)
        }
    }
)

// Might be a better way to accomplish this but found this to be the most reliable way to reset the current selected layout.
// Only called when the user navigates away from app studio, actual logic is handled in the reducer.
export const resetCurrentSelectedLayout = createAsyncThunk(
    'phoenix/resetCurrentLayout',
    async () => {}
)

const phoenixLayoutsSlice = createSlice({
    name: 'phoenix-layouts',
    initialState,
    reducers: {
        cloneBlockTemplateToLayoutOptimistically: (
            state,
            action: PayloadAction<{
                layoutId: string
                block: PhoenixBlockTemplate
                position: number
                tab?: string
            }>
        ) => {
            const { layoutId, block, position, tab: tabTitle } = action.payload
            const layout = state.currentSelectedLayout

            if (layout && layout._id === layoutId) {
                if (layout.contentLayout === 'list')
                    // @ts-expect-error - We are optimistically adding a block to the layout, the block is not a PhoenixBlock yet
                    layout.blocks.splice(position, 0, block)
                else if (layout.contentLayout === 'tabbed-list') {
                    const tab = layout.content?.tabs.find(
                        (t) => t.title === tabTitle
                    )

                    if (tab) {
                        // @ts-expect-error - We are optimistically adding a block to the layout, the block is not a PhoenixBlock yet
                        tab.blocks.splice(position, 0, block)
                    }
                }
            }
        },
        updateLayoutBlockListOptimistically: (
            state,
            action: PayloadAction<{
                layoutId: string
                blockIds: string[]
                tab?: string
            }>
        ) => {
            const { layoutId, blockIds } = action.payload
            const layout = state.currentSelectedLayout

            if (layout && layout._id === layoutId) {
                const newBlockList: PhoenixBlock[] = []

                if (layout.contentLayout === 'list') {
                    for (const blockId of blockIds) {
                        const blockIndex = layout.blocks.findIndex(
                            (block) => block._id === blockId
                        )
                        if (blockIndex === -1) {
                            return
                        }

                        const block = layout.blocks[blockIndex]
                        newBlockList.push(block)
                    }

                    layout.blocks = newBlockList
                } else if (layout.contentLayout === 'tabbed-list') {
                    const tab = layout.content?.tabs.find(
                        (t) => t.title === action.payload.tab
                    )

                    if (!tab) return

                    for (const blockId of blockIds) {
                        const block = tab.blocks.find(
                            (block) => block._id === blockId
                        )

                        if (!block) {
                            return
                        }

                        newBlockList.push(block)
                    }

                    tab.blocks = newBlockList
                }
            }
        },
        updateLayoutSettingsOptimistically: (
            state,
            action: PayloadAction<{
                layoutId: string
                skipUpdatingStateWithAPIResponse?: boolean
                contentLayout?: PhoenixLayout['contentLayout']
                settingsConfig?: Partial<PhoenixLayout['settingsConfig']>
                content?: PhoenixLayout['content']
                alignBlocksAndFirstTab?: boolean
            }>
        ) => {
            const {
                layoutId,
                settingsConfig = {},
                contentLayout,
                content,
                skipUpdatingStateWithAPIResponse = false,
                alignBlocksAndFirstTab = false,
            } = action.payload
            const layout = state.currentSelectedLayout

            if (layout && layout._id === layoutId) {
                layout.skipStateUpdateWithAPIResponse =
                    skipUpdatingStateWithAPIResponse

                layout.settingsConfig = {
                    ...layout.settingsConfig,
                    ...settingsConfig,
                }

                if (contentLayout) {
                    layout.contentLayout = contentLayout

                    if (content) {
                        if (content.tabs) {
                            content.tabs = content.tabs.map((tab) => {
                                return {
                                    ...tab,
                                    blocks: tab.blocks.map((block) => ({
                                        ...block,
                                        code: atob(block.code),
                                    })),
                                }
                            })
                        }

                        layout.content = content
                    }

                    // Essentially when you swap between single page and multi page layouts,
                    // update the first tab's blocks or layout blocks with the respective blocks array so the user does not lose progress
                    if (alignBlocksAndFirstTab) {
                        if (layout.contentLayout === 'tabbed-list') {
                            // Ensure the first tab has blocks
                            if (!layout.content?.tabs) {
                                layout.content = {
                                    horizontalScroll:
                                        layout.content?.horizontalScroll ??
                                        false,
                                    tabs: [
                                        {
                                            title: 'Tab 1',
                                            key: Date.now(),
                                            blocks: layout.blocks,
                                            contentType: 'blocks',
                                        },
                                        {
                                            title: 'Tab 2',
                                            key: Date.now() + 1,
                                            blocks: [],
                                            contentType: 'blocks',
                                        },
                                    ],
                                }
                            }

                            layout.content.tabs[0].blocks = layout.blocks
                        } else if (layout.contentLayout === 'list') {
                            layout.blocks =
                                layout.content?.tabs[0].blocks ?? layout.blocks
                        }
                    }
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(cloneLayoutToDraft.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(cloneLayoutToDraft.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'
            state.currentSelectedLayout = payload
            addLayoutToList(state, payload)
        })
        builder.addCase(cloneLayoutToDraft.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(updateDraftVersion.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateDraftVersion.fulfilled, (state, action) => {
            const updatedLayout = action.payload
            if (
                state.currentSelectedLayout &&
                state.currentSelectedLayout._id === updatedLayout._id
            ) {
                state.currentSelectedLayout = updatedLayout
            }
        })
        builder.addCase(updateDraftVersion.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(fetchDraftLayoutByType.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            fetchDraftLayoutByType.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'

                if (payload) {
                    addLayoutToList(state, payload)
                    state.currentSelectedLayout = payload
                }

                state.initialized = true
            }
        )
        builder.addCase(fetchDraftLayoutByType.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(fetchLayoutById.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchLayoutById.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'

            if (payload) {
                addLayoutToList(state, payload)
                state.currentSelectedLayout = payload
            }

            state.initialized = true
        })
        builder.addCase(fetchLayoutById.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(fetchLayoutsByType.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchLayoutsByType.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'

            payload.forEach((layout) => {
                addLayoutToList(state, layout)
            })
            state.initialized = true
        })
        builder.addCase(fetchLayoutsByType.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(savePhoenixBlock.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(savePhoenixBlock.fulfilled, (state, action) => {
            state.loading = 'fulfilled'

            const layout = state.currentSelectedLayout!
            const block = action.payload
            const tab = action.meta.arg.tab
            updateBlockInLayout(block, layout, tab)
        })
        builder.addCase(savePhoenixBlock.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(revertPhoenixBlock.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(revertPhoenixBlock.fulfilled, (state, action) => {
            state.loading = 'fulfilled'

            const layout = state.currentSelectedLayout!
            const block = action.payload
            const tab = action.meta.arg.tab
            updateBlockInLayout(block, layout, tab)
        })
        builder.addCase(revertPhoenixBlock.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(replaceBlockInLayout.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            replaceBlockInLayout.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'
                state.currentSelectedLayout = payload.layout
                addLayoutToList(state, payload.layout)
            }
        )
        builder.addCase(replaceBlockInLayout.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(publishLayout.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(publishLayout.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'
            state.currentSelectedLayout = payload

            addLayoutToList(state, payload)
        })
        builder.addCase(publishLayout.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(updateLayoutSettings.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            updateLayoutSettings.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'

                // skipStateUpdateWithAPIResponse exists as a temp field on the layout to prevent updating the currentSelectedLayout
                // State updates appear instantaneous to the user, but when we set the currentSelectedLayout to the response, it can be jumpy since some values may have changed since then due to additional user input.
                // In those cases, there's likely another API request that is still in progress that will update the layout when it returns, but 9 times out of 10 we can get away with not updating the state
                // Current reasons to update the state are a new layout being cloned and subsequent changes being made, or the contentLayout changing and needing to hydrate the block list from the API
                // See MultiTab/index.tsx for an example of when we don't want to update the state
                if (state.currentSelectedLayout?.skipStateUpdateWithAPIResponse)
                    return

                addLayoutToList(state, payload)
            }
        )
        builder.addCase(updateLayoutSettings.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(cloneBlockTemplateToLayout.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            cloneBlockTemplateToLayout.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'
                state.currentSelectedLayout = payload
                addLayoutToList(state, payload)
            }
        )
        builder.addCase(
            cloneBlockTemplateToLayout.rejected,
            (state, action) => {
                state.loading = 'rejected'
                state.error = action.error.message
            }
        )
        builder.addCase(updateLayoutBlockList.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            updateLayoutBlockList.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'
                state.currentSelectedLayout = payload
                addLayoutToList(state, payload)
            }
        )
        builder.addCase(updateLayoutBlockList.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(resetCurrentSelectedLayout.pending, (state) => {
            state.currentSelectedLayout = null
        })
        builder.addCase(archiveLayoutById.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(archiveLayoutById.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'
            state.currentSelectedLayout = payload
            addLayoutToList(state, payload)
        })
        builder.addCase(archiveLayoutById.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(unscheduleLayoutById.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            unscheduleLayoutById.fulfilled,
            (state, { payload }) => {
                state.loading = 'fulfilled'
                state.currentSelectedLayout = payload

                addLayoutToList(state, payload)
            }
        )
        builder.addCase(unscheduleLayoutById.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
    },
})

const addLayoutToList = (state: PhoenixLayoutState, layout: PhoenixLayout) => {
    // Check if hydrated
    let layoutIsHydrated = true
    if (layout.contentLayout === 'tabbed-list') {
        layoutIsHydrated =
            layout.content?.tabs.every((tab) =>
                tab.blocks.every((block) => block.code)
            ) || false
    } else if (layout.blocks.some((block) => !block.code))
        layoutIsHydrated = false

    if (!layoutIsHydrated) return

    // Remove the layout if it already exists
    state.fetchedLayouts = state.fetchedLayouts.filter(
        (l) => l._id !== layout._id
    )

    // Add it to state
    state.fetchedLayouts.push(layout)

    if (state.currentSelectedLayout?._id === layout._id) {
        state.currentSelectedLayout = layout
    }
}

const updateBlockInLayout = (
    block: PhoenixBlock,
    layout: PhoenixLayout,
    tabTitle?: string
) => {
    if (layout.contentLayout === 'list') {
        const blockIndex = layout.blocks.findIndex(
            (layoutBlock) => layoutBlock._id === block._id
        )

        if (blockIndex === -1) return

        layout.blocks[blockIndex] = block
    } else if (layout.contentLayout === 'tabbed-list') {
        const tab = layout.content?.tabs.find((tab) => tab.title === tabTitle)

        if (!tab) return

        const blockIndex = tab.blocks.findIndex(
            (layoutBlock) => layoutBlock._id === block._id
        )

        if (blockIndex === -1) return

        tab.blocks[blockIndex] = block
    }
}

const compareBlocks = (a: PhoenixBlock, b: PhoenixBlock) => {
    // Quick check is if the block uses the template or not
    // Longer check is if the code or manifest config have changed
    return (
        a.useBlockTemplate !== b.useBlockTemplate ||
        a.code.length !== b.code.length ||
        a.code !== b.code ||
        !_.isEqual(a.manifestConfig, b.manifestConfig)
    )
}

export const draftLayoutHasChanges = (state: RootState): boolean => {
    if (!state.phoenixLayouts.currentSelectedLayout) return false

    if (state.phoenixLayouts.currentSelectedLayout.status !== 'draft')
        return false

    const previouslyPublishedLayout = selectLastPublishedLayout(
        state.phoenixLayouts.currentSelectedLayout.type,
        state
    )

    if (!previouslyPublishedLayout) return true

    const currentSelectedLayout = state.phoenixLayouts.currentSelectedLayout

    if (currentSelectedLayout?._id === previouslyPublishedLayout?._id)
        return false

    // Compare settings configs
    if (
        !_.isEqual(
            previouslyPublishedLayout.settingsConfig,
            currentSelectedLayout.settingsConfig
        )
    )
        return true

    // At this point, the changes in the blocks could be in the ordering or the content
    // Layouts can have either a single list content, or a tabbed list (multiple lists)
    // We need to check if the content layout is a tabbed list or a single list and compare it respectively

    if (
        currentSelectedLayout.contentLayout !==
        previouslyPublishedLayout.contentLayout
    )
        return true

    const contentLayout = currentSelectedLayout.contentLayout

    if (contentLayout === 'tabbed-list') {
        if (
            !previouslyPublishedLayout.content ||
            !currentSelectedLayout.content
        )
            return true

        if (
            previouslyPublishedLayout.content.horizontalScroll !==
            currentSelectedLayout.content.horizontalScroll
        )
            return true

        // Compare tab ordering
        if (
            !_.isEqual(
                previouslyPublishedLayout.content.tabs.map((tab) => tab.title),
                currentSelectedLayout.content.tabs.map((tab) => tab.title)
            )
        )
            return true

        // Compare each tab
        return currentSelectedLayout.content.tabs.some((tab) => {
            // Find previous layout's tab
            const previousTab = previouslyPublishedLayout.content?.tabs.find(
                (prevTab) => prevTab.title === tab.title
            )

            if (!previousTab) return true

            // Compare block ordering within tab
            if (
                !_.isEqual(
                    previousTab.blocks.map((block) => block._id),
                    tab.blocks.map((block) => block._id)
                )
            )
                return true

            // Check each block
            return tab.blocks.some((block) => {
                const previousBlock = previousTab.blocks.find(
                    (prevBlock) => prevBlock._id === block._id
                )

                // Once published, blocks become read only. If an edit was made, then it would create a new block
                // Meaning there are changes that are unpublished
                if (!previousBlock) return true

                compareBlocks(block, previousBlock)
            })
        })
    }

    // Compare block ordering
    if (
        !_.isEqual(
            previouslyPublishedLayout.blocks.map((block) => block._id),
            currentSelectedLayout.blocks.map((block) => block._id)
        )
    )
        return true

    // Check each block, if the code is different or there are manifest config or options changes, return true
    return currentSelectedLayout.blocks.some((block) => {
        const previousBlock = previouslyPublishedLayout.blocks.find(
            (prevBlock) => prevBlock._id === block._id
        )

        // Once published, blocks become read only. If an edit was made, then it would create a new block
        // Meaning there are changes that are unpublished
        if (!previousBlock) return true

        return compareBlocks(block, previousBlock)
    })
}

export const selectLayoutsByStatusAndType = (
    type: LayoutType | undefined,
    status: LayoutStatus,
    state: RootState
): PhoenixLayout[] => {
    if (!type) return []

    return state.phoenixLayouts.fetchedLayouts
        .filter((layout) => layout.type === type && layout.status === status)
        .sort((a, b) => {
            return (
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
            )
        })
}

// There should always be a previously published layout
export const selectLastPublishedLayout = (
    type: LayoutType,
    state: RootState
): PhoenixLayout | null => {
    if (!state.phoenixLayouts.fetchedLayouts.length) return null

    // Find all layouts of the type and read only
    const layouts = state.phoenixLayouts.fetchedLayouts.filter(
        (layout) => layout.type === type && layout.readOnly
    )
    if (!layouts.length) return null

    // Find the latest layout
    const latestLayout = layouts.reduce((prev, current) =>
        prev.updatedAt > current.updatedAt ? prev : current
    )
    return latestLayout
}

export const selectCurrentSelectedLayout = (
    state: RootState
): PhoenixLayout | null => {
    return state.phoenixLayouts.currentSelectedLayout
}

export const selectLoadedLayoutWithTemplates = createSelector(
    [selectCurrentSelectedLayout, selectCBTEntities],
    (layout, customBlockTemplates) => {
        if (!layout) return null

        const blocks = layout?.blocks.map((block) => {
            if (!customBlockTemplates) return block

            if (
                customBlockTemplates?.[block?.blockTemplateId] &&
                block.useBlockTemplate
            ) {
                const customBlockTemplate =
                    customBlockTemplates[block?.blockTemplateId]

                if (!customBlockTemplate) return block

                return {
                    ...block,
                    code: customBlockTemplate.code,
                }
            }
            return block
        })

        return { ...layout, blocks }
    }
)

export default phoenixLayoutsSlice.reducer

export const {
    updateLayoutSettingsOptimistically,
    cloneBlockTemplateToLayoutOptimistically,
    updateLayoutBlockListOptimistically,
} = phoenixLayoutsSlice.actions

const decodeBlock = (block: PhoenixBlock): PhoenixBlock => {
    if (!block.code) return block

    return {
        ...block,
        code: atob(block.code),
    }
}

const decodeLayout = (layout: PhoenixLayout): PhoenixLayout => {
    if (layout.contentLayout === 'tabbed-list') {
        const decodedTabs =
            layout.content?.tabs?.map((tab) => {
                const decodedBlocks = tab.blocks.map(decodeBlock)

                return {
                    ...tab,
                    blocks: decodedBlocks,
                }
            }) || []

        return {
            ...layout,
            content: {
                horizontalScroll: layout.content?.horizontalScroll || false,
                tabs: decodedTabs,
            },
        }
    }

    const decodedBlocks: PhoenixBlock[] = layout.blocks.map(decodeBlock)

    return { ...layout, blocks: decodedBlocks }
}
