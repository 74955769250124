import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store'
import customBlockTemplateAdapter from './adapters'

const customBlockTemplateSelectors = customBlockTemplateAdapter.getSelectors(
    (state) => state.appStudioBlockTemplate?.customBlockTemplates
)

const {
    selectEntities: selectCBTEntities,
    selectById: selectCBTById,
    selectAll: selectAllCBTs,
} = customBlockTemplateSelectors

const selectBlockBankLoading = (state: RootState) => {
    return state.appStudioBlockTemplate.loading === 'pending'
}

const selectLegacyCustomBlockTemplates = (state: RootState) =>
    state.appStudioBlockTemplate.legacyCustomBlockTemplates

const selectCustomBlockTemplates = (state: RootState) => selectAllCBTs(state)

const selectCustomBlockTemplateById = (state: RootState, id) =>
    selectCBTById(state, id)

const selectHelloWorldTemplate = (state: RootState) =>
    state.appStudioBlockTemplate.helloWorldTemplate

const selectCustomBlockBank = createSelector(
    [
        selectHelloWorldTemplate,
        selectCustomBlockTemplates,
        selectLegacyCustomBlockTemplates,
    ],
    (helloWorldTemplate, customBlockTemplates, legacyBlockTemplates) => {
        return [
            {
                key: 'custom-blocks',
                label: 'Custom Blocks',
                blockTemplates: customBlockTemplates,
            },
            {
                key: 'legacy-custom-blocks',
                label: 'Legacy Custom Blocks',
                blockTemplates: legacyBlockTemplates,
            },
        ]
    }
)

export {
    selectBlockBankLoading,
    selectCustomBlockBank,
    selectCustomBlockTemplateById,
    selectCBTById,
    selectAllCBTs,
    selectCBTEntities,
}
